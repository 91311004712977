<template>
    <div class="modal fade modalbox" id="viewCardApplication" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        REF-#{{application.id}}
                        <span class="text-end">
                            <small class="badge badge-warning" v-if="application.data.status==='PENDING'">{{ application.data.status }}</small>
                            <small class="badge badge-success" v-else-if="application.data.status==='APPROVED'">{{ application.data.status }}</small>
                            <small class="badge badge-danger" v-else-if="application.data.status==='DECLINED'">{{ application.data.status }}</small>
                        </span>
                    </h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="col-12">
                            <h4>Government Issued ID</h4>
                        </div>
                        <template v-if="application.data.gCard">
                            <p class="text-center">Front View</p>
                            <div class="row justify-content-center" v-if="application.data.gCard.front">
                                <div class="col-10 front">
                                    <img :src="application.data.gCard.front" alt="id front image" class="imaged img-fluid">
                                </div>
                            </div>
                            <p class="text-center mt-3">Back View</p>
                            <div class="row justify-content-center" v-if="application.data.gCard.back">
                                <div class="col-10 front">
                                    <img :src="application.data.gCard.back" alt="id front image" class="imaged img-fluid">
                                </div>
                            </div>
                        </template>

                        <div class="col-12 mt-4">
                            <h4>Voters Card</h4>
                        </div>
                        <template v-if="application.data.vCard">
                            <div class="row justify-content-center" v-if="application.data.vCard.front">
                                <div class="col-10 front">
                                    <img :src="application.data.vCard.front" alt="id image" class="imaged img-fluid">
                                </div>
                            </div>
                        </template>

                        <div class="col-12 mt-4">
                            <h4>Social Security Number (SSN)</h4>
                            <p>{{application.data.ssn}}</p>
                        </div>

                        <div class="col-12">
                            <h4>Card Number</h4>
                            <p>{{application.data.assignedNumber||'Not Assigned'}}</p>
                        </div>

                        <div class="col-12">
                            <h4>Card CVV Number</h4>
                            <p>{{application.data.assignedCVV||'Not Assigned'}}</p>
                        </div>

                        <div class="col-12">
                            <h4>Created</h4>
                            <p>{{getReadableDatetime(application.data.createdAt)}}</p>
                        </div>

                        <div class="col-12">
                            <h4>Expire</h4>
                            <p>{{getReadableDatetime(application.data.expireAt)}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from '../../../utils/methodsMixin';

export default {
    name: 'viewCardApplication',
    mixins: [methodsMixin],
    props: {
        application: {
            type: Object
        }
    }
};
</script>

<style scoped>
.front{
    height: 150px;
}
.front img{
    height: inherit;
}
</style>